input {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 18px;
  width: 100%;
  outline: none;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  padding: 10px 20px;
  font-size: 20px;
  width: 100%;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  background: #bb2528;
  color: white;
  transition: background 0.2s linear;
}

button:hover {
  background: #ea4630;
}

button[disabled] {
  background: #ea4630;
}

#app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #146b3a;
  overflow-y: auto;
  overflow-x: hidden;
}

#decoration {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
}

#decoration img {
  max-width: 100%;
  height: auto;
}

#homePage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#homePage h1 {
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;
}

#homePage #loginBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  padding-bottom: 50px;
}

#homePage #loginBox #loginBoxError {
  color: white;
  margin-bottom: 10px;
}

#homePage #loginBox form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}

#homePage #loginBox form > div {
  display: flex;
  justify-content: center;
}

#homePage #loginBox #loginBoxInput {
  margin-bottom: 10px;
}

#choosedPage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#choosedPage #wheel > g:last-of-type {
  pointer-events: none;
}

#choosedPage #wheel > g:last-of-type > path {
  pointer-events: none;
  filter: none;
}

#choosedPage #wheel > g:last-of-type > text {
  display: none;
}

#choosedPage #owner {
  font-weight: 500;
  color: white;
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
}

#choosedPage #choosed {
  font-weight: 500;
  color: white;
  text-align: center;
  font-size: 24px;
  margin-bottom: 50px;
}

#adminPage {
  padding: 20px;
  padding-top: 100px;
  max-width: 500px;
  margin: 0 auto;
}

#adminPage #users {
  margin-bottom: 20px;
}

#adminPage #users .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  margin-bottom: 10px;
}

#adminPage #users .user .name {
  font-weight: 500;
  margin-top: 5px;
}

#adminPage #users .user .choosed {
  margin-top: 5px;
}

#adminPage #users .user .remove {
  padding: 0 10px;
  font-size: 24px;
  cursor: pointer;
  flex-grow: 0;
}

#adminPage form > div {
  display: flex;
  justify-content: center;
}

#adminPage #adminPageError {
  color: white;
}

#adminPage #addUser {
  border: 1px solid white;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
}

#adminPage #addUser #addUserError {
  color: white;
  margin-bottom: 10px;
}

#adminPage #addUser input {
  margin-bottom: 10px;
}

#adminPage #enableGame {
  margin-bottom: 10px;
}
